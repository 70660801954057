import React from 'react';
import training from "../store/training";
import {observer} from "mobx-react-lite";
import {motion} from "framer-motion";
import animation from "../store/animation";

const BigButton = observer(() => {


  return (
    <div className={'level-five'}>



      {training.bigButtonPressed ? null : (
        <>


          <motion.div
            variants={animation.bigButton}
            initial={'hidden'}
            animate={'visible'}

            className="big-button-block">
            <div className="big-button-block__stand--top"></div>
            <div className="big-button-block__stand"></div>
            <div className="big-button-block__btn-stand"></div>
            <div
              className="big-button-block__btn-border big-button-block__btn-border--press"

            ></div>

            <button
              className="big-button-block__red-button"
              type="button"
              onClick={() => training.setBigButtonPressed(true)}
            >
              красная кнопка
            </button>
          </motion.div>
        </>

      )}
    </div>



  );
});

export default BigButton;