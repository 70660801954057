import {makeAutoObservable} from "mobx";

function animation() {
  return makeAutoObservable({
    dragConstraints: {left: 0, top: 0, right: 0, bottom: 0},
    dragElastic: 0.7,



    headingVariants: {
      hidden: {
        y: '-100vw',
        opacity: 0,
      },
      visible: {
        y: 0,
        opacity: 1,
        transition: {
          type: 'spring',
          stiffness: 30,
          duration: 3,
          mass: .9,
          damping: 8,
        }
      },
      exit: {
        y: '-100vw',
        transition: {ease: 'easeInOut', duration: 2}
      }
    },

    buttonVariants: [
      // level 0 (game)
      {
        visible: {
          opacity: 1,
          scale: 1,
          transition: {ease: 'easeInOut', duration: 0.3}
        },
        hidden: {
          opacity: .6,
          scale: 0,
          transition: {ease: 'easeInOut', duration: 2.8}
        },

      },

      // level 1
      {
        hidden: {
          y: '100vh',
        },
        visible: {
          y: 0,
          transition: {
            delay: 2,
            type: 'spring',
            stiffness: 130,
            mass: 5,
            damping: 15,
          }
        }
      },

      // level 2
      {
        visible: i => ({ // i from custom property, its id
          opacity: 1,
          transition: {
            delay: i * 0.25, // 0.3
          },
        }),
        hidden: { opacity: 0 },
        exit: i =>  ({
          x: Math.floor(-60 + Math.random() * (120)) +'vw',
          y: '100vh',
          transition: {ease: 'easeInOut', duration: 1.8},
        })

      },

      // level 3
      {
        visible: {
          x: 0,
          y: 0,
          transition: {ease: 'easeInOut', duration: 1.8 }
        },

        hidden: {
          x: Math.floor(-60 + Math.random() * (60)) +'vw',
          y: '-100vh'
        },

        exit: i =>  ({
          x: Math.floor(-60 + Math.random() * (120)) +'vw',
          y: '100vh',
          transition: {ease: 'easeInOut', duration: 1.8},
        })
      },

      // level 4
      {
        visible: {
          opacity: 1,
          scale: 1,
          transition: {ease: 'easeInOut', duration: 0.3}
        },
        hidden: {
          opacity: .6,
          scale: 0,
          transition: {ease: 'easeInOut', duration: 2.8}
        },

      }
    ],

    gameStartButton: {
      hidden: {
        x: '-100vw',
      },
      visible: {
        x: 0,
        transition: {
          type: 'spring',
          stiffness: 50,
          duration: 1,
          mass: 1,
          damping: 10,
          delay: 0.7
        },

      },
    },

    bigButton: {
      hidden: {
        y: '100vh',
      },
      visible: {
        y: 0,
        transition: {


          duration: 3,

          delay: 0.7
        },

      },
    },

    statisticButton: {
      hidden: {
        x: '-100vw',
      },
      visible: {
        x: 0,
        transition: {
          type: 'spring',
          stiffness: 50,
          duration: 1,
          mass: 1,
          damping: 10,
          delay: 0.7
        },

      },
    }







  })
}

export default animation();