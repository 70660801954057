import React from 'react';
import {observer} from "mobx-react-lite";
import statistic from "../store/statistic";
import training from '../store/training'
import {motion} from "framer-motion";
import animation from "../store/animation";

const GameStatistic = observer(() => {
  return (
    <div className={'statistic'}>
      <p>Набрано очков: 0</p>
      <p>Нажато кнопок: {statistic.clicksOnButton + statistic.clicksOnRedButton}</p>
      <p>Бесполезных «тыков» по экрану: {statistic.allClicks}</p>

      <motion.button
        variants={animation.statisticButton}
        initial={'hidden'}
        animate={'visible'}
        onClick={() => {
          training.resetSubLevel();
          training.setCurrentLevel(1)
        }}
        className={'statistic__button'}
      >
        Попробовать всё-таки поиграть
      </motion.button>
    </div>
  );
});

export default GameStatistic;