import {makeAutoObservable} from "mobx";

function answers() {
  return makeAutoObservable({

    // game
    headingsLevelZero: {
      instructions: [
        {
          heading: 'Всё просто: зелёная кнопка даёт +3 очка и +1 секунду, фиолетовая +1 очко, жёлтая -2 секунды, красная — конец игры. Удачи!',
          emotion: 'SmilingFaceWithSmilingEyes'
        },
        {
          heading: 'Напоминаю: зелёная кнопка это +3 очка и +1 секунда, фиолетовая +1 очко, жёлтая забирает 2 секунды, красная — конец игры.',
          emotion: 'SmilingFaceWithSmilingEyes'
        },
        {
          heading: 'Ну, ты знаешь: зелёная кнопка даёт три очка и одну секунду, фиолетовая даёт очко, жёлтая забирает две секунды, красная — конец игры.',
          emotion: 'SmilingFaceWithSmilingEyes'
        },
        {
          heading: 'Тыкай по зелёным и фиолетовым! Не тыкай по красным! Удачи!',
          emotion: 'SmilingFaceWithSmilingEyes'
        },
        {
          heading: 'Ты знаешь, что делать!',
          emotion: 'WinkingFace'
        },
      ],
      results: {
        lose: [
          { heading: 'Эх! Не та кнопка', emotion: 'ExpressionlessFace' },
          { heading: 'Это была красная кнопка', emotion: 'ExpressionlessFace' },
          { heading: 'Не повезло', emotion: 'ExpressionlessFace' },
          { heading: 'Жаль, но эта игра проиграна', emotion: 'ExpressionlessFace' },
          { heading: 'Ну что сказать? Не надо было нажимать на красную!', emotion: 'UnamusedFace' },
          { heading: 'Эм... Бывает!', emotion: 'ExpressionlessFace' },
        ],

        bad: [
          { heading: 'Ты можешь лучше!', emotion: 'SlightlyFrowningFace' },
          { heading: 'Слабовато...', emotion: 'SlightlyFrowningFace' },
          { heading: 'Скажу честно: это не самый лучший результат', emotion: 'SlightlyFrowningFace' },
          { heading: 'Маловато будет', emotion: 'SlightlyFrowningFace' },
          { heading: 'Пффф! Всего-то?!', emotion: 'SlightlyFrowningFace' },
        ],

        normal: [
          { heading: 'Неплохой результат!', emotion: 'SlightlySmilingFace' },
          { heading: 'У тебя хорошо получается!', emotion: 'SlightlySmilingFace' },
          { heading: 'Нормально!', emotion: 'SlightlySmilingFace' },
          { heading: 'Неплохо!', emotion: 'SlightlySmilingFace' },
          { heading: 'Хороший результат, поздравляю!', emotion: 'SlightlySmilingFace' },

        ],

        good: [
          { heading: 'Вау! Супер, поздравляю!', emotion: 'SmilingFaceWithSmilingEyes' },
          { heading: 'Отличный результат!', emotion: 'SmilingFaceWithSmilingEyes' },
          { heading: 'Очень хороший результат!', emotion: 'SmilingFaceWithSmilingEyes' },
          { heading: 'Вау! Это было классно!', emotion: 'SmilingFaceWithSmilingEyes' },
          { heading: 'Мне понравилось! Отличный результат!', emotion: 'SmilingFaceWithSmilingEyes' },
        ],

        excellent: [
          { heading: 'Даже у меня не получилось бы лучше!', emotion: 'HuggingFace' },
          { heading: 'Превосходный результат!', emotion: 'HuggingFace' },
          { heading: 'Ничего себе! Как тебе это удалось?!', emotion: 'HuggingFace' },
          { heading: 'Ого-го! Вот это результат!', emotion: 'HuggingFace' },
          { heading: 'Надеюсь, ты играешь без читов! Крутой результат!', emotion: 'HuggingFace' },
          { heading: 'Вот это скорость! Вот это результат!!!', emotion: 'HuggingFace' },
        ]
      }
    },


    // training
    headingsLevelOne: [
      { heading: 'Главное правило этой игры: не нажимай на красную кнопку!', emotion: 'SlightlySmilingFace'},
      { heading: 'Правильно!', emotion: 'GrinningFace'},
      { heading: 'Жми куда угодно, только не на красную кнопку!', emotion: 'GrinningFaceWithBigEyes'},
      { heading: 'Приятно иметь дело с умным человеком!', emotion: 'SmilingFaceWithSmilingEyes'},
      { heading: 'Продолжай в том-же духе и у тебя всё получится!', emotion: 'WinkingFace'},
      { heading: 'Да, мы с тобой поладим!', emotion: 'SlightlySmilingFace'},
    ],

    headingsLevelTwo: [
      // subLevel 0 - click on first button
      {
        Fuchsia: {
          heading:
            'Шикарный выбор для начала! Это была кнопка цвета «Фуксия». Равная смесь синего и красного.',
          emotion: 'WinkingFace',
        }, // 😉
        Purple: {
          heading: 'Кнопка пурпурного цвета — отличный выбор для начала!',
          emotion: 'SmilingFaceWithHeartEyes',
        }, // 😍
        White: {
          heading: 'Белая — первая! Отличный выбор для первой кнопки.',
          emotion: 'SmilingFaceWithSmilingEyes',
        },
        Gray: { heading: 'Обычная серая кнопка', emotion: 'FacewithRollingEyes' },
        Maroon: { heading: 'Английское и французское название цвета этой кнопки происходит от слова «каштан», хотя она тёмно-бордовая', emotion: 'NerdFace' },
        Yellow: { heading: 'Желтый был любимым цветом Ван Гога. На его полотнах присутствовала вся гамма желтого.', emotion: 'SmilingFaceWithSmilingEyes' },
        Lime: { heading: 'Если идти от зелёного цвета к жёлтому, то на три четверти пути будет цвет «лайм». Интересное начало, да?', emotion: 'SmirkingFace' },
        Aqua: { heading: 'Вау, начинаем с кнопки цвета морской волны! Может, ты хочешь на море?', emotion: 'GrinningFaceWithBigEyes' },
        Teal: { heading: 'Интересный выбор для начала. Говорят, этот цвет был популярен в Америке в 90х', emotion: 'WinkingFace' },
        Blue: { heading: 'Это была синяя кнопка. Хорошее начало, мне нравится!', emotion: 'SlightlySmilingFace' },
        Navy: { heading: 'И начинаем мы с тёмно-синей кнопки. ОК. Идём дальше!', emotion: 'SlightlySmilingFace' },
        Orange: { heading: 'Начинаем с оранжевой кнопки! Любишь апельсины?!', emotion: 'SlightlySmilingFace' },
        Silver: { heading: 'Это была кнопка серебряного цвета. Какая следующая?!', emotion: 'SlightlySmilingFace' },
        Brown: { heading: 'Начинаем с коричневой кнопки! Это неспектральный цвет. Интересный выбор!', emotion: 'SlightlySmilingFace' },
        Black: { heading: 'Это была самая незаметная кнопка! Чёрная кнопка на чёрном фоне, как тебе это удалось?', emotion: 'GrinningFace' },
      },

      // subLevel 1 - just click on button
      {
        Fuchsia: {
          heading:
            'Фуксия — цвет, названный в честь цветка, который был назван в честь учёного XVI века Леонарта фон Фукса',
          emotion: 'NerdFace',
        }, // 🤓
        Purple: {
          heading:
            'Пурпурный цвет. Самый красивый из неспектральных цветов. Нравилась мне эта кнопка...',
          emotion: 'WorriedFace',
        }, // 😟
        White: {
          heading:
            'А ты знаешь, что щенки далматинцев рождаются полностью белыми? Белыми, как была эта кнопка!',
          emotion: 'UpsideDownFace',
        },
        Gray: {
          heading:
            'Первоначально слова «серый» и «седой» были однокоренными и означали «нечто без цвета».',
          emotion: 'ThinkingFace',
        },
        Maroon: { heading: 'Это была тёмно-бордовая кнопка, но испанцы бы назвали этот цвет «гранатовым»!', emotion: 'ThinkingFace' },
        Yellow: { heading: 'Жёлтый – самый светлый и самый светящийся цвет спектра. Кнопка явно напрашивалась!', emotion: 'FaceWithTearsOfJoy' },
        Lime: { heading: 'Цвет этой кнопки называется «лайм». Интересно, что было раньше, цвет или фрукт?', emotion: 'ThinkingFace' },
        Aqua: { heading: 'Кнопка цвета морской волны. Этот цвет находится чётко между синим и зелёным.', emotion: 'NerdFace' },
        Teal: { heading: 'Кнопка цвета «teal». Этот цвет назван в честь небольшой речной утки.', emotion: 'NerdFace' },
        Blue: { heading: 'Цвет неба кажется синим вследствие рэлеевского рассеивания солнечного света.', emotion: 'NerdFace' },
        Navy: { heading: 'Когда-то цвет этой кнопки называли морским синим. Теперь же это просто тёмно-синий.', emotion: 'NerdFace' },
        Orange: { heading: 'Кнопка оражевого цвета. Цвет назван в честь апельсина!', emotion: 'GrinningFaceWithSmilingEyes' },
        Silver: { heading: 'Обычная серебряная кнопка', emotion: 'FacewithRollingEyes' },
        Brown: { heading: 'Кнопка коричевого цвета. Это наименее любимый цвет у людей', emotion: 'NeutralFace' },
        Black: { heading: 'А это была чёрная кнопка', emotion: 'UnamusedFace' },
      },

      // subLevel 2 - click on last button
      {
        Fuchsia: {
          heading:
            'Завершаем нажатия кнопок! Напомню главное правило: не нажимай красную кнопку!',
          emotion: 'WinkingFace',
        }, // 😉
        Purple: {
          heading:
            'И пурпурная кнопка завершает нажатия кнопок на сегодня! Напоминаю: не нажимай на красную кнопку.',
          emotion: 'SlightlySmilingFace',
        }, // 🙂
        White: { heading: 'Ну и белая в самом конце, правильно! Красную не трогай.', emotion: 'WinkingFace' },
        Gray: { heading: 'Попрощались с банальной серой кнопкой! Про красную даже не думай!', emotion: 'WinkingFace' },
        Maroon: { heading: 'Эта была кнопка с оттенком красного цвета. Но саму красную не нажимай!', emotion: 'WinkingFace' },
        Yellow: { heading: 'Желтая карточка в футболе это предупреждение. И здесь тоже. Больше никаких кнопок!', emotion: 'WinkingFace' },
        Lime: { heading: 'И на кнопке с чудесным фруктовым цветом «лайм» мы завершаем!', emotion: 'WinkingFace' },
        Aqua: { heading: 'И кнопкой с цветом морской волны мы заканчиваем нажатия на сегодня!', emotion: 'WinkingFace' },
        Teal: { heading: 'Ну и хватит, на последнюю кнопку не нажимай!', emotion: 'WinkingFace' },
        Blue: { heading: 'Ну и на синей кнопке мы заканчиваем, потому что этот цвет один из основных в системе RGB.  Красную не нажимай!', emotion: 'WinkingFace' },
        Navy: { heading: 'И тёмно-синяя кнопка завершает нажатие кнопок! Не нажимай на красную!', emotion: 'WinkingFace' },
        Orange: { heading: 'Оранжевая кнопка. Цвет находится между жёлтым и красным. А на красную нажимать нельзя!', emotion: 'WinkingFace' },
        Silver: { heading: 'И серебряной кнопкой мы завершаем нажатия на кнопки', emotion: 'WinkingFace' },
        Brown: { heading: 'Правильно! Закончим коричневой кнопкой. На красную не нажимай!', emotion: 'WinkingFace' },
        Black: { heading: 'И чёрной кнопкой мы заканчиваем нажимать на кнопки! К красной даже не тянись!', emotion: 'WinkingFace' },
      },
    ],



    headingsLevelThree: {
      currentAnswer: [
        { heading: 'Понятно, ты любишь нажимать на красные кнопки. Приступай, всё равно не угадаешь правильную!', emotion: 'UpsideDownFace' },
        { heading: 'Повезло! Усложним задачу.', emotion: 'UnamusedFace' },
        { heading: 'Да, это была она... Но теперь нужная кнопка спрятана ещё лучше', emotion: 'UnamusedFace' },
        { heading: 'Добавлю больше кнопок', emotion: 'UnamusedFace' },
        { heading: 'Что скажешь теперь?!', emotion: 'SmirkingFace' },
        { heading: 'Сдавайся!', emotion: 'SmirkingFace' },

      ],
      lastButton: [
        { heading: 'Всё, больше не нажимай!', emotion: 'WinkingFace' },
        { heading: 'Даже не думай!', emotion: 'WinkingFace' },
        {
          heading: 'Да, вот она, последняя. Слабо не нажимать на неё?!',
          emotion: 'WinkingFace',
        },
        {
          heading:
            'Сама судьба оттягивала этот момент как могла. Но ты же всё равно нажмёшь?',
          emotion: 'WinkingFace',
        },
        {
          heading: 'Нет, это не специально так. Тебе просто не везёт!',
          emotion: 'WinkingFace',
        },
        {
          heading:
            'Колоссальное невезение! Мне нравится это слово, «колоссальное». Редко приходится его использовать в речи',
          emotion: 'WinkingFace',
        },
      ],

      currentAnswerOnLastButton: [
        {
          heading: 'Так ведь интереснее, делать без разрешения?',
          emotion: 'UnamusedFace',
        },
        { heading: 'Как же тебя остановить?!', emotion: 'UnamusedFace' },
        {
          heading: 'Ясно, вообще нет силы воли, да? Тогда вот, развлекайся!',
          emotion: 'FacewithRollingEyes',
        },
        { heading: 'Ну и ладно!', emotion: 'NeutralFace' },
        {
          heading: 'Кто бы сомневался!',
          emotion: 'FacewithRollingEyes',
        },
        {
          heading: 'Кажется, мне нужно придумать что-то посложнее',
          emotion: 'ThinkingFace',
        },
      ],
      easy: [
        { heading: 'Не-а!', emotion: 'SlightlySmilingFace' },
        { heading: 'Мимо!', emotion: 'SlightlySmilingFace' },
        { heading: 'Не она!', emotion: 'SlightlySmilingFace' },
        { heading: 'Даже близко не она!', emotion: 'SlightlySmilingFace' },
        { heading: 'Нет!', emotion: 'SlightlySmilingFace' },
        { heading: 'Не эта кнопка!', emotion: 'SlightlySmilingFace' },
        { heading: 'Ха! Мимо!', emotion: 'SlightlySmilingFace' },
        { heading: 'Пф, даже не рядом!', emotion: 'SlightlySmilingFace' },
        { heading: 'Ха! Не она!', emotion: 'SlightlySmilingFace' },
        { heading: 'Снова мимо!', emotion: 'SlightlySmilingFace' },
        { heading: 'Не найдёшь!', emotion: 'SlightlySmilingFace' },
        { heading: 'Промах!', emotion: 'SlightlySmilingFace' },
        { heading: 'Конечно мимо!', emotion: 'SlightlySmilingFace' },
        { heading: 'И не эта!', emotion: 'SlightlySmilingFace' },
        { heading: 'Ммм... нет!', emotion: 'SlightlySmilingFace' },
        { heading: 'Нет', emotion: 'SlightlySmilingFace' },
        { heading: 'Неа!', emotion: 'SlightlySmilingFace' },
        { heading: 'Неа, не она', emotion: 'SlightlySmilingFace' },
        { heading: 'Опять мимо', emotion: 'SlightlySmilingFace' },

      ],
      medium: [

        { heading: 'Как всегда мимо!', emotion: 'GrinningFaceWithSmilingEyes' },
        { heading: 'Опять не она!', emotion: 'GrinningFaceWithSmilingEyes' },
        { heading: 'Нет! Опять мимо!', emotion: 'GrinningFaceWithSmilingEyes' },
        { heading: 'И не эта!', emotion: 'GrinningFaceWithSmilingEyes' },
        { heading: 'Опять мимо!', emotion: 'GrinningFaceWithSmilingEyes' },
        { heading: 'Неа.', emotion: 'GrinningFaceWithSmilingEyes' },
        { heading: 'Снова нет.', emotion: 'GrinningFaceWithSmilingEyes' },
        { heading: 'Неа!', emotion: 'GrinningFaceWithSmilingEyes' },
        { heading: 'Нет, нет, no, nicht', emotion: 'GrinningFaceWithSmilingEyes' },
        { heading: 'И не эта кнопка тоже!', emotion: 'GrinningFaceWithSmilingEyes' },
        { heading: 'Очередной промах!', emotion: 'GrinningFaceWithSmilingEyes' },
        { heading: 'Почти! Но всё равно мимо', emotion: 'GrinningFaceWithSmilingEyes' },
        { heading: 'Конечно, когда-нибудь у тебя получится. Но не сейчас', emotion: 'GrinningFaceWithSmilingEyes' },
        { heading: 'Оу! Показалось, что это была она, но нет!', emotion: 'GrinningFaceWithSmilingEyes' },
        { heading: 'Снова мимо', emotion: 'GrinningFaceWithSmilingEyes' },
        { heading: 'Снова не она', emotion: 'GrinningFaceWithSmilingEyes' },
        { heading: 'Опять не она', emotion: 'GrinningFaceWithSmilingEyes' },
        { heading: 'И в этот раз не она', emotion: 'GrinningFaceWithSmilingEyes' },

      ],
      hard: [
        { heading: 'Отрицательное попадание', emotion: 'GrinningSquintingFace' },
        { heading: 'С цветными было проще, правда?!', emotion: 'GrinningSquintingFace' },
        {
          heading: 'Неужели так сложно найти правильную красную кнопку?',
          emotion: 'GrinningSquintingFace',
        },
        {
          heading:
            'Если я скажу тебе: «Не думай о красной кнопке», о чём ты будешь думать?',
          emotion: 'GrinningSquintingFace',
        },
        {
          heading: 'Ну серьёзно, зачем тебе это?',
          emotion: 'GrinningSquintingFace',
        },
        {
          heading: 'Уже не так весело нажимать на красные кнопки?!',
          emotion: 'GrinningSquintingFace',
        },
        { heading: 'У тебя не получится!', emotion: 'GrinningSquintingFace' },
        { heading: 'Ты не найдёшь нужную кнопку!', emotion: 'GrinningSquintingFace' },
        {
          heading:
            'Может, единственный способ победить в этой игре — это не играть?',
          emotion: 'GrinningSquintingFace',
        },
        { heading: 'А может её здесь вообще нет?!', emotion: 'GrinningSquintingFace' },
        {
          heading:
            'А что, если не нажимать на кнопки? Попробуй для разнообразия!',
          emotion: 'GrinningSquintingFace',
        },
        { heading: 'Могу дать подсказку! Но не буду.', emotion: 'GrinningSquintingFace' },
        {
          heading: 'Правильной кнопки не существует! Шучу, она где-то здесь.',
          emotion: 'GrinningSquintingFace',
        },
        {
          heading:
            'Хьюстон, у нас проблема. Мы не можем найти красную кнопку среди красных кнопок.',
          emotion: 'GrinningSquintingFace',
        },
        {
          heading:
            'Чтобы найти красную кнопку, надо думать, как красная кнопка! Надо чувствовать себя красной кнопкой! Надо стать красной кнопкой!',
          emotion: 'GrinningSquintingFace',
        },
        {
          heading: 'А знаешь, кто ещё любил нажимать на красные кнопки?',
          emotion: 'FaceWithTearsOfJoy',
        },
      ],
    },


    headingsLevelFour: {
      currentAnswer: [
        { heading: 'У тебя есть ровно пять секунд, чтобы нажать на красную кнопку. И две уже прошли.',
          emotion: 'SlightlySmilingFace' },
        { heading: 'Ускорю до 1 секунды!', emotion: 'WinkingFace' },
        { heading: 'Выходим на сверхсекундную скорость! Кнопка обновляется каждые 0.8 секунды!', emotion: 'WinkingFace' },
        { heading: 'Хорошо, я буду менять место кнопки каждые 700 миллисекунд!', emotion: 'WinkingFace' },
        { heading: 'Ладно, ускоряемся! 600 мс', emotion: 'FaceWithTearsOfJoy', },
        { heading: 'Пол секунды! Сдавайся!', emotion: 'SmirkingFace' },
      ],

      slowly: [
        { heading: 'Слишком медленно!', emotion: 'SlightlySmilingFace' },
        { heading: 'Кнопка уже в другом месте!', emotion: 'SlightlySmilingFace' },
        { heading: 'Очень медленно!', emotion: 'SlightlySmilingFace' },
        { heading: 'Давай быстрее!', emotion: 'SlightlySmilingFace' },
        { heading: 'Мимо!', emotion: 'SlightlySmilingFace' },
        { heading: 'Она уже переместилась!', emotion: 'SlightlySmilingFace' },
        { heading: 'Не успеваешь!', emotion: 'SlightlySmilingFace' },
        { heading: 'Поторопись! Это слишком медленно!', emotion: 'SlightlySmilingFace' },
      ],

    },


  })
}

export default answers()