import React from 'react';
import {observer} from "mobx-react-lite";

import game from "../store/game";

const ScoreBoard = observer(() => {
  return (
    <div>
      Время: {game.gameTime}
      <br/>
      Очки: {game.score}
    </div>
  );
});

export default ScoreBoard;