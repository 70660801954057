import React, {useEffect} from 'react';
import BaseButton from "./BaseButton";
import assistant from "../store/assistant";
import training from "../store/training";
import answers from "../store/answers";

import {motion} from "framer-motion";
import animation from "../store/animation";
import statistic from "../store/statistic";

const LevelOne = () => {
  useEffect(()=> {

    training.setCurrentLevel(1);

    training.resetGames();
    statistic.resetStatistic();

    training.resetSubLevel();

    assistant.updateAssistant(answers.headingsLevelOne[training.currentSubLevel]);

  }, []);

  return (
    <motion.div
      className={'level-one'}
      variants={animation.buttonVariants[training.currentLevel]}
      initial={'hidden'}
      animate={'visible'}

    >
      <BaseButton id={1} color={'Red'} />
    </motion.div>
  );
};

export default LevelOne;