import React from 'react';
import {observer} from "mobx-react-lite";
import assistant from "../store/assistant";
import {debounce} from "lodash/function";
import training from "../store/training";
import animation from "../store/animation";
import game from "../store/game";
import {motion, AnimatePresence} from "framer-motion";
import ScoreBoard from "./ScoreBoard";

const assistantReaction = () => {
  assistant.reactionOnClicks()
}

const handlerLodashDebounce = debounce(assistantReaction, 750);


const Assistant = observer(() => {

  return (
    !training.bigButtonPressed && (

      <div className="assistant">

          <motion.div
            initial={{opacity: 0, x: '-100vw'}}
            animate={{opacity: 1, x: 0}}
            transition={{type: 'spring', stiffness: 80}}
            className={'assistant__emoji'}
            onClick={() => {
              assistant.increaseClicks();
              handlerLodashDebounce();
            }}>
            <motion.div
              drag
              dragConstraints={animation.dragConstraints}
              dragElastic={animation.dragElastic}
            >
              <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                {assistant.assistant[assistant.currentEmotion]}
              </motion.div>

            </motion.div>
          </motion.div>



          <AnimatePresence>

            <div>
              <motion.h1
                variants={animation.headingVariants}
                initial={'hidden'}
                animate={'visible'}
                exit={'exit'}

                className="assistant__heading"
              >
                {game.gameStatus === 1 ? <ScoreBoard  /> : assistant.currentHeading}
                

              </motion.h1>


            </div>



          </AnimatePresence>



        </div>

    )
  );
});

export default Assistant;