import React, {useEffect} from 'react';
import BaseButton from "./BaseButton";
import training from "../store/training";
import {motion, AnimatePresence} from "framer-motion";
import {observer} from "mobx-react-lite";
import assistant from "../store/assistant";
import answers from "../store/answers";
import animation from "../store/animation";


const container = {
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.3,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
}


const LevelThree = observer(() => {
  useEffect(() => {
    training.resetSubLevel();

    assistant.updateAssistant(answers.headingsLevelThree.currentAnswer[training.currentSubLevel]);

    training.updateButtonsForLevelThree(training.currentSubLevel);

  }, []);



  // when subLevel change
  useEffect(() => {

    if (training.currentSubLevel > 0) {
      training.resetButtonsForLevelThree(training.currentSubLevel);
    } else {
      training.updateButtonsForLevelThree(training.currentSubLevel);
    }


    updateRandomTrueButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [training.currentSubLevel])


  const updateRandomTrueButton = () => {
    // random number
    const randomID = Math.floor(Math.random() * (training.amountButtonsForLevelThree[training.currentSubLevel]));
    training.updateTrueButtonIdForLevelThree(randomID);

  };




  return (
    <motion.div
      className={`level-three level-three--sub-level-${training.currentSubLevel}`}

      variants={container}
      initial="hidden"
      animate="visible"
      exit={{
        x: Math.floor(-60 + Math.random() * (120)) +'vw',
        y: '100vh',
        transition: {ease: 'easeInOut', duration: 1.8},
      }}
    >
      <AnimatePresence mode={"popLayout"}>
        {training.buttonsForLevelThree.map((color, id) => {
          return (
            <div key={id + color + 'level-three-' + training.currentSubLevel}>

              {
                color ?
                  <div>
                    <motion.div

                      variants={animation.buttonVariants[training.currentLevel]}

                      initial={'hidden'}
                      animate={'visible'}

                      exit={'exit'}

                      custom={id}

                    >
                      <BaseButton
                        color={'Red'}
                        id={id}
                      />
                    </motion.div>
                  </div>
                  :
                  (<div></div>)
              }

            </div>

          )

        })}

      </AnimatePresence>







    </motion.div>
  );
});

export default LevelThree;