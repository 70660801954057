import {makeAutoObservable} from "mobx";

function assistant() {
  return makeAutoObservable({
    assistant: {
      // Открытая радость
      GrinningFace: "😀", // use

      // Большая открытая радость
      GrinningFaceWithBigEyes: "😃", // use

      // Крайне открытая радость
      GrinningFaceWithSmilingEyes: "😄",

      // Радость на 32 зуба
      BeamingFaceWithSmilingEyes: "😁",

      // Невероятная радость
      GrinningSquintingFace: "😆", // use

      // Радость до холодного пота
      GrinningFaceWithSweat: "😅",

      // смех до слёз
      FaceWithTearsOfJoy: "😂",

      // широкий спектр положительных, счастливых и дружественных настроений. Тон настроения может быть покровительствованным, пассивно-агрессивным или ироническим.
      SlightlySmilingFace: "🙂", // use

      // используется для передачи иронии, сарказма, шутки или ощущения озвучивания глупости.
      UpsideDownFace: "🙃",

      // может означать шутку, флирт, скрытое значение или общую позитивность. Тон настроения может быть игривым, ласковым, наводящим на размышления или ироническим. Не путайте с более озорным или сексуальным 😏
      WinkingFace: "😉", // use

      // выражает подлинное счастье и теплые, положительные чувства.
      SmilingFaceWithSmilingEyes: "😊", // use

      // символизирует ангельское поведение, добрые дела. В религиозном контексте может означать ангела, молитву, благословение.
      SmilingFaceWithHalo: "😇",

      // часто передает чувства любви, увлечения и обожания.
      SmilingFaceWithHeartEyes: "😍",

      // воздушный поцелуй. Может представлять поцелуй на прощание или спокойной ночи, также может выражать чувство любви и привязанности в целом.
      FaceBlowingAKiss: "😘",

      // поцелуй. Передаёт чувства любви и привязанности. Похож на 😙 и 😚, но с менее близкими глазами.
      // В сочетании с 🎵 может означать насвистывание и передавать такие чувства как удивление, восхищение, презрение или симулированную невиновность.
      KissingFace: "😗",

      // Передает широкий спектр теплых, положительных чувств, включая любовь, счастье и благодарность. Не путайте с 😊 и с 🙂.
      SmilingFace: "☺️", // use

      // поцелуй. Передает чувства любви и привязанности. Считается более интимным, чем 😙 и 😗.
      KissingFaceWithClosedEyes: "😚",

      // поцелуй. Передаёт чувства любви и привязанности, но менее интимное, чем 😚.
      // В сочетании с 🎵 может означать насвистывание и передавать такие чувства как удивление, восхищение, презрение или симулированную невиновность.
      KissingFaceWithSmilingEyes: "😙",

      // символизирует аппетит и удовлетворённость. Широко используется, как обозначение вкусной еды.
      // Может также означать, что человек привлекателен.
      FaceSavoringFood: "😋",

      FaceWithTongue: "😛",
      WinkingFaceWithTongue: "😜",
      SquintingFaceWithTongue: "😝",
      MoneyMouthFace: "🤑",
      HuggingFace: "🤗",
      ThinkingFace: "🤔",
      ZipperMouthFace: "🤐",
      NeutralFace: "😐",
      ExpressionlessFace: "😑",
      FaceWithoutMouth: "😶",
      SmirkingFace: "😏",
      UnamusedFace: "😒",
      FacewithRollingEyes: "🙄",
      GrimacingFace: "😬",
      RelievedFace: "😌",
      PensiveFace: "😔",
      SleepyFace: "😪",
      SleepingFace: "😴",
      FaceWithMedicalMask: "😷",
      FaceWithThermometer: "🤒",
      FaceWithHeadBandage: "🤕",
      DizzyFace: "😵",
      SmilingFaceWithSunglasses: "😎",
      NerdFace: "🤓",
      ConfusedFace: "😕",
      WorriedFace: "😟",
      SlightlyFrowningFace: "🙁",
      FrowningFace: "☹️",
      FaceWithOpenMouth: "😮",
      HushedFace: "😯",
      AstonishedFace: "😲",
      FlushedFace: "😳",
      FrowningFaceWithOpenMouth: "😦",
      AnguishedFace: "😧",
      FearfulFace: "😨",
      AnxiousFaceWithSweat: "😰",
      SadButRelievedFace: "😥",
      CryingFace: "😢",
      LoudlyCryingFace: "😭",
      FaceScreamingInFear: "😱",
      ConfoundedFace: "😖",
      PerseveringFace: "😣",
      DisappointedFace: "😞",
      DowncastFacWithSweat: "😓",
      WearyFace: "😩",
      TiredFace: "😫",
      FaceWithSteamFromNose: "😤",
      PoutingFace: "😡",
      AngryFace: "😠", // use
      SmilingFaceWithHorns: "😈",
      AngryFaceWithHorn: "👿", // use
    },

    currentEmotion: "",
    currentHeading: "",

    tempSaveAssistantEmotion: '',
    tempSaveAssistantHeading: '',

    allClicks: 0,

    clicks: 0, // for debounce

    /**
     *
     * @param
     *  answer: object
     *
     */
    updateAssistant(answer) {
      this.currentHeading = answer.heading;
      this.currentEmotion = answer.emotion;
    },


    increaseClicks() {
      this.allClicks = this.allClicks + 1;
      this.clicks = this.clicks + 1;
    },

    returnOldEmotionAssistant(oldEmotion) {

      setTimeout(() => {
        if (oldEmotion === this.currentEmotion) {
          // ассистента не меняли за 2 секунды извне
          this.updateAssistant({heading: this.tempSaveAssistantHeading, emotion: this.tempSaveAssistantEmotion})
        }
      }, 2000);
    },



    reactionOnClicks() {

      this.tempSaveAssistantEmotion = this.currentEmotion;
      this.tempSaveAssistantHeading = this.currentHeading;


      if (this.clicks >= 4 && this.clicks <= 9) {
        // кликнули от 4 до 9 раз
        this.updateAssistant({ heading: this.currentHeading, emotion: 'SmilingFaceWithSmilingEyes'});
      } else if (this.clicks >= 10 && this.clicks <= 19) {
        // кликнули от 10 до 19 раз
        this.updateAssistant({ heading: this.currentHeading, emotion: 'AngryFace'});
      } else if (this.clicks >= 20) {
        // кликнули 20 или больше раз
        this.updateAssistant({ heading: this.currentHeading, emotion: 'AngryFaceWithHorn'});

      }

      this.clicks = 0;

      const tempEmotion = this.currentEmotion;

      this.returnOldEmotionAssistant(tempEmotion)

    }

  })
}

export default assistant()