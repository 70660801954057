import React, {useEffect} from 'react';
import {observer} from "mobx-react-lite";
import training from "../store/training";

const CrashScreen = observer(() => {


  useEffect(() => {

    training.increaseSubLevel();

    const intervalScreenCrash = setInterval(() => {
      training.increaseSubLevel();

      if (training.currentSubLevel === 3) {
        clearInterval(intervalScreenCrash);

        setTimeout(() => {
          training.setBigButtonPressed(false);
          training.resetSubLevel();
          training.setCurrentLevel(1)
        }, 5000)

      }

    }, 650);


  }, [])



  return (
    <div className={'crash-screen'}>
      {training.currentSubLevel <= 3 && (
        <picture>
          <source srcSet={`/images/crack${training.currentSubLevel}.avif`} type="image/avif" />

          <source srcSet={`/images/crack${training.currentSubLevel}.webp`} type="image/webp"/>

          <img
            className="crash-screen__image"
            alt=""
            src={`/images/crack${training.currentSubLevel}.png`}
          />
        </picture>
      )}
    </div>
  );
});

export default CrashScreen;