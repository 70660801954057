import React, {useEffect} from 'react';
import './styles/base.scss'
import training from "./store/training";
import Assistant from "./components/Assistant";

import statistic from "./store/statistic";

import GameField from "./components/GameField";
import assistant from "./store/assistant";
import answers from "./store/answers";


const App = () => {

  useEffect(()=> {
    statistic.setStartGameTime();
  }, []);

  const justClick = () => {
    statistic.clickOnGame(training.currentLevel);

    if (training.currentLevel === 1) {

      /**
       * if amount clicks === number from array stepsToGameFromLevelOne
       * we change assistant and sublevel
       */
      if (training.stepsToGameFromLevelOne.includes(statistic.allClicks - assistant.allClicks)) {
        training.increaseSubLevel();
        assistant.updateAssistant(answers.headingsLevelOne[training.currentSubLevel]);
      }

      /**
       * if amount clicks enough to start game
       * we start game
       */
      if (statistic.allClicks - assistant.allClicks === training.clicksForStartGame) {
        // go to game
        training.setCurrentLevel(0);
      }

    }

    if (training.currentLevel === 4) {
      function getRandomAnswerForLevelFour() {
        const answerID = Math.floor(Math.random() * (answers.headingsLevelFour.slowly.length ));


        if (assistant.currentHeading === answers.headingsLevelFour.slowly[answerID].heading) {
          return getRandomAnswerForLevelFour();
        } else {
          return answerID;
        }
      }
      const randomAnswerID = getRandomAnswerForLevelFour();

      assistant.updateAssistant(answers.headingsLevelFour.slowly[randomAnswerID]);
    }
  }


  return (
    <>
      <main className={'game'}

            onClick={() => justClick()}

            onContextMenu={(event) => {
              event.preventDefault();
              statistic.clickOnGame();
            }}

      >
        <header className={'game__header'}>
          <Assistant />
        </header>

        <GameField />
      </main>



    </>



  );
};

export default App;