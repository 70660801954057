import React from 'react';
import training from "../store/training";
import {observer} from "mobx-react-lite";

import LevelOne from "./LevelOne";
import LevelTwo from "./LevelTwo";
import LevelThree from "./LevelThree";
import LevelFour from "./LevelFour";
import Game from "./Game";
import EndTraining from "./EndTraining";
import CrashScreen from "./CrashScreen";

const GameField = observer(() => {
  return (
    <div className="game__field">
      <div className="level">
        {training.currentLevel === 0 && <Game />}
        {training.currentLevel === 1 && <LevelOne />}
        {training.currentLevel === 2 && <LevelTwo />}
        {training.currentLevel === 3 && <LevelThree />}
        {training.currentLevel === 4 && <LevelFour />}
        {training.currentLevel === 5 && <EndTraining />}
        {training.currentLevel === 6 && <CrashScreen />}
      </div>

    </div>
  );
});

export default GameField;