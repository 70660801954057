import React, {useEffect} from 'react';
import BigButton from "./BigButton";
import GameStatistic from "./GameStatistic";
import assistant from "../store/assistant";
import training from "../store/training";

const EndTraining = () => {
  useEffect(() => {
    training.resetSubLevel();
    assistant.updateAssistant({
      heading: 'Ладно, будем считать, что это была тренировка. Может теперь поиграем?',
      emotion: 'WinkingFace'
    })
  }, [])
  return (
    <div className={'end-training'}>
      <GameStatistic />
      <BigButton />
    </div>
  );
};

export default EndTraining;