import React, {useEffect} from 'react';
import {observer} from "mobx-react-lite";
import assistant from "../store/assistant";
import BaseButton from "./BaseButton";
import training from "../store/training";
import game from "../store/game";
import {motion} from "framer-motion";
import animation from "../store/animation";
import answers from "../store/answers";


const Game = observer(() => {
  const getRandomId = (min, max) => {
    // случайное число от min до (max)
    const rand = min + Math.random() * (max - min);
    return Math.floor(rand);
  }

  useEffect(() => {
    switch (game.gameStatus) {
      case 0:

        const instructionID = answers.headingsLevelZero.instructions.length >= game.gamesAmount ? game.gamesAmount : answers.headingsLevelZero.instructions.length - 1;

        assistant.updateAssistant(answers.headingsLevelZero.instructions[instructionID]);

        game.preparationsGame();

        break;

      case 1:
        game.startNewGame();
        break;

      case 2:
        game.endGame();


        if (game.gameTime > 0) {
          const randomAnswerID = getRandomId(0, answers.headingsLevelZero.results.lose.length);

          assistant.updateAssistant(answers.headingsLevelZero.results.lose[randomAnswerID]);

        } else if (game.score <= 50) {
          const randomAnswerID = getRandomId(0, answers.headingsLevelZero.results.bad.length);

          assistant.updateAssistant(answers.headingsLevelZero.results.bad[randomAnswerID]);
        } else if (game.score > 50 && game.score <= 100) {
          const randomAnswerID = getRandomId(0, answers.headingsLevelZero.results.normal.length);

          assistant.updateAssistant(answers.headingsLevelZero.results.normal[randomAnswerID]);
        } else if (game.score > 100 && game.score <= 200) {
          const randomAnswerID = getRandomId(0, answers.headingsLevelZero.results.good.length);

          assistant.updateAssistant(answers.headingsLevelZero.results.good[randomAnswerID]);
        } else {
          const randomAnswerID = getRandomId(0, answers.headingsLevelZero.results.excellent.length);

          assistant.updateAssistant(answers.headingsLevelZero.results.excellent[randomAnswerID]);
        }


        break;

      default:
        console.warn('невозможный вариант в игре');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [game.gameStatus])


  return (
    <>
      {game.gameStatus === 2 ? (
          <div className={'game-result-score'}>
            <h2 className={'game-result-score__heading'}>
              {game.score > 0 ? (<span>Набрано очков: {game.score}</span>) : (<span>Игра проиграна</span>)}
            </h2>

            <motion.button
              variants={animation.gameStartButton}
              initial={'hidden'}
              animate={'visible'}

              onClick={() => {
              game.preparationsGame()
            }} className={'game-result-score__button'}>
              Ещё раз!
            </motion.button>


          </div>
        ) : (
          <div className={'level-zero'}>
            {
              game.gameFieldButtons.map((button, id) => {
                return (
                  <div key={id + 'level-zero'}>
                    {button.length ? (
                      <div>
                        <motion.div
                          variants={animation.buttonVariants[training.currentLevel]}
                          initial={'hidden'}
                          animate={'visible'}
                        >
                          <BaseButton id={id} color={button}/>
                        </motion.div>
                      </div>
                    ) : (<div key={id}></div>)}
                  </div>
                )
              })
            }
          </div>
        )
      }
    </>

  );
});

export default Game;