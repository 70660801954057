import React from 'react';

import training from "../store/training";
import game from "../store/game";
import answers from "../store/answers";
import statistic from "../store/statistic";
import assistant from "../store/assistant";
import {observer} from "mobx-react-lite";


const randomInteger = (min, max) => {
  // случайное число от min до (max)
  const rand = min + Math.random() * (max - min);
  return Math.floor(rand);
}







const BaseButton = observer((props) => {

  const buttonClick = (color, id) => {
    statistic.clickOnButton(color);

    switch (training.currentLevel) {
      case 0:


        if (color === 'Red') {
          game.resetScore();
          game.endGame();
        } else {
          if (game.gameStatus === 0) {
            game.changeGameStatus(1);
            game.moveButton(color)
          } else {
            if (game.gameStatus === 1) {
              if (color === 'Green') {
                game.updateGameGreenButtonPressed();
              } else if (color === 'Purple') {
                game.updateGamePurpleButtonPressed();
              } else if (color === 'Yellow') {
                game.updateGameYellowButtonPressed();
              } else {
                console.warn('невозможный вариант на уровне 0')
              }
            }
          }
        }







        if (game.trapColor) {
          game.hiddenButtonInTrap(id);
        }



        break;

      case 1:
        if (color === 'Red') {
          training.setCurrentLevel(training.currentLevel + 1);
        } else {
          console.warn('невозможный вариант на первом уровне')
        }

        break;

      case 2:
        training.updatePressedButtonsForLevelTwoMix(color);

        if (color === 'Red') {
          training.setCurrentLevel(training.currentLevel + 1);
        } else {
          training.increaseButtonsPressedLevelTwo();


          if (training.buttonsPressedLevelTwo === training.buttonColorsForLevelTwo.length - 1) {
            // stay red button and one color
            training.increaseSubLevel();
          }

          assistant.updateAssistant(answers.headingsLevelTwo[training.currentSubLevel][color]);

          if (training.buttonsPressedLevelTwo === 1 ) {
            // first press on button
            training.increaseSubLevel();
          }
        }
        break;

      case 3:

        if (id === training.trueButtonIdForLevelThree) {
          // угадали красную кнопку

          // выбираем повышать подуровень или идти на другой уровень
          if (training.amountButtonsForLevelThree.length - 1 > training.currentSubLevel) {
            // повышаем subLevel
            training.increaseSubLevel();



            // проверяем на последней кнопке угадали или нет
            if (training.pressedButtonsForLevelThree.length + 1 === training.amountButtonsForLevelThree[training.currentSubLevel - 1]) {
              // да, дошли до последней кнопки

              assistant.updateAssistant(answers.headingsLevelThree.currentAnswerOnLastButton[training.currentSubLevel]);
            } else {
              // нет, кнопки ещё были
              assistant.updateAssistant(answers.headingsLevelThree.currentAnswer[training.currentSubLevel]);
            }

            // сбрасываем массив с нажатыми кнопками
            training.resetPressedButtonsForLevelThree();


          } else if (training.amountButtonsForLevelThree.length - 1 === training.currentSubLevel) {
            // уровень пройден
            training.setCurrentLevel(training.currentLevel + 1);

          } else {
            console.warn('невозможный вариант на уровне 3')
          }
        }
        else {
          // не угадали красную кнопку

          training.updatePressedButtonsForLevelThree(id);


          // смотрим предпоследняя кнопка или нет
          if (training.pressedButtonsForLevelThree.length === training.amountButtonsForLevelThree[training.currentSubLevel] - 1) {
            // осталась последняя кнопка

            assistant.updateAssistant(answers.headingsLevelThree.lastButton[training.currentSubLevel]);


          } else {
            // ещё есть кнопки в ассортименте
            let arrayKey = 'easy';

            // выбираем ключ к ответу
            if (training.pressedButtonsForLevelThree.length <= (training.amountButtonsForLevelThree[training.currentSubLevel] / 3)) {
              // мало кликов, отвечаем easy
              arrayKey = 'easy';
            }
            else if (training.pressedButtonsForLevelThree.length > (training.amountButtonsForLevelThree[training.currentSubLevel] / 3 ) && training.pressedButtonsForLevelThree.length <= (training.amountButtonsForLevelThree[training.currentSubLevel] / 2)) {
              // есть клики, отвечаем medium
              arrayKey = 'medium';
            }
            else {
              // много кликов
              arrayKey = 'hard'
            }


            let randomAnswerID = randomInteger(0, answers.headingsLevelThree[arrayKey].length );

            function checkAnswer() {
              if (assistant.currentHeading === answers.headingsLevelThree[arrayKey][randomAnswerID].heading) {
                randomAnswerID = randomInteger(0, answers.headingsLevelThree[arrayKey].length );
                checkAnswer();
              }
            }
            checkAnswer();

            assistant.updateAssistant(answers.headingsLevelThree[arrayKey][randomAnswerID]);
          }



        }

        break;

      case 4:

        if (color === 'Red') {
          // успели нажать

          if (training.currentSubLevel + 1 < training.secondsForLevelFour.length) {
            // повышаем subLevel
            training.increaseSubLevel();
            assistant.updateAssistant(answers.headingsLevelFour.currentAnswer[training.currentSubLevel]);
          } else {
            // переходим на следующий уровень
            training.setCurrentLevel(5);
          }
        } else {
          console.warn('невозможный вариант на уровне 4');
        }
        break;

      default:
        console.warn('невозможный вариант при нажатии на кнопку')
    }

  }

  return (
    <div className={'button__container'}>

        <button

          onClick={(event) => {
            event.stopPropagation();
            buttonClick(props.color, props.id);
          }}
          type={'button'}
          className={`button button--base ${props.hiddenButton ? 'button--off' : ''} `}
          style={{backgroundColor: props.color}}
          disabled={props.disabled}
        >
          кнопка
        </button>


    </div>

  );
});

export default BaseButton;