import {makeAutoObservable} from "mobx";


function game() {
  return makeAutoObservable({
    gamesAmount: 0,

    gameStatus: 0, // 0, 1, 2

    changeGameStatus(status) {
      this.gameStatus = status;
    },


    score: 0,
    gameTime: 10, // 30 seconds

    gameYellowButtonPressed: 0,
    gameGreenButtonPressed: 0,
    gamePurpleButtonPressed: 0,

    gameFieldButtons: [],

    gameInterval: null,

    redButtonInterval: null,
    yellowButtonInterval: null,
    greenButtonInterval: null,
    purpleButtonInterval: null,

    trap: null,
    trapColor: null,

    resetScore() {
      this.score = 0;
    },


    preparationsGame() {

      this.updateGameFieldButtons();

      this.moveButton('Red');
      this.moveButton('Green');
      this.moveButton('Yellow');
      this.moveButton('Purple');

      this.score = 0;

      if (this.gameStatus !== 0) {
        this.gameStatus = 0;
      }

      if (this.gameTime !== 10) {
        this.gameTime = 10;
      }

    },


    startNewGame() {
      this.startGameInterval(); // time

      this.startRedButtonInterval();
      this.startYellowButtonInterval();
      this.startGreenButtonInterval();
      this.startPurpleButtonInterval();

      this.startTrapInterval();
    },

    endGame() {

      this.increaseGamesAmount();

      this.resetGameInterval();
      this.resetTrapInterval();

      this.resetRedButtonInterval();
      this.resetYellowButtonInterval();
      this.resetGreenButtonInterval();
      this.resetPurpleButtonInterval();

      if (this.gameStatus !== 2) {
        this.gameStatus = 2;
      }

    },

    decreaseGameTime(sec) {
      this.gameTime = this.gameTime - sec;
    },

    startGameInterval() {
      this.gameInterval = setInterval(() => {
        this.decreaseGameTime(1);

        if (this.gameTime < 0) {
          this.changeGameStatus(2);
        }

      }, 1000)
    },
    resetGameInterval() {
      clearInterval(this.gameInterval);
    },

    startTrapInterval() {
      this.trap = setTimeout(() => {

        // good trap its green and purple colors
        // bad trap its red and yellow
        const goodTrapOrNot = Math.random() < 0.25;


        if (goodTrapOrNot) {
          this.trapColor = Math.random() < 0.5 ? 'Green' : 'Purple';
        } else {
          this.trapColor = Math.random() < 0.5 ? 'Red' : 'Yellow';
        }


        this.startTrap(this.trapColor);

        // обнуляем ловушку
        setTimeout(() => this.endTrap(), this.getRandomTime(2300, 3600)) //


        this.startTrapInterval();
      }, this.getRandomTime(7000, 12000)); // 10000, 16000
    },



    startTrap(color) {
      this.gameFieldButtons = this.gameFieldButtons.map((button) => {
        return button.length ? button : color;
      });
    },

    endTrap() {
      // Nach dem TRAP
      // ist vor dem TRAP
      // S. Herberger

      let catchFirstTrapColor = false;


      const array = this.gameFieldButtons.map((button) => {
        if (button === this.trapColor) {
          if (catchFirstTrapColor) {
            return '';
          } else {
            catchFirstTrapColor = true;
            return button;
          }

        } else {

          return button;
        }
      });



      this.gameFieldButtons = array;
      this.trapColor = null;


    },

    resetTrapInterval() {
      clearTimeout(this.trap);
    },

    hiddenButtonInTrap(id) {
      this.gameFieldButtons = this.gameFieldButtons.map((button, buttonID) => {
        return buttonID === id ? '' : button;
      });
    },




    startRedButtonInterval() {
      this.redButtonInterval = setTimeout(() => {
        this.moveButton('Red');
        this.startRedButtonInterval();
      }, this.getRandomTime(700, 1400))
    },
    resetRedButtonInterval() {
      clearTimeout(this.redButtonInterval);
    },

    startYellowButtonInterval() {
      this.yellowButtonInterval = setTimeout(() => {
        this.moveButton('Yellow');
        this.startYellowButtonInterval();
      }, this.getRandomTime(1300, 1700))
    },
    resetYellowButtonInterval() {
      clearTimeout(this.yellowButtonInterval);
      this.yellowButtonInterval = false;
    },

    startGreenButtonInterval() {
      this.greenButtonInterval = setTimeout(() => {
        this.moveButton('Green');
        if (this.gameStatus === 1) {
          this.startGreenButtonInterval();
        }

      }, this.getRandomTime(700, 1200))
    },
    resetGreenButtonInterval() {
      clearTimeout(this.greenButtonInterval);
      this.greenButtonInterval = false;
    },

    startPurpleButtonInterval() {

      this.purpleButtonInterval = setTimeout(() => {
        this.moveButton('Purple');
        this.startPurpleButtonInterval();
      }, this.getRandomTime(1000, 1500))
    },
    resetPurpleButtonInterval() {
      clearTimeout(this.purpleButtonInterval);
      this.purpleButtonInterval = null;
    },




    getRandomTime(min, max) {
      return Math.floor(min + Math.random() * (max + 1 - min));
    },


    moveButton(color) {
      const newPlace = this.getRandomPlaceForButton();

      if (newPlace !== false && color !== this.trapColor) {
        this.gameFieldButtons = this.gameFieldButtons.map((button, id) => {
          if (button === color) {
            return ''
          } else {
            return id === newPlace ? color : button;
          }
        });
      }

    },

    getRandomPlaceForButton() {
      if (this.gameFieldButtons.includes('')) {
        // есть свободные места
        const randomID = Math.floor(Math.random() * (this.gameFieldButtons.length));

        if (this.gameFieldButtons[randomID]) {
          return this.getRandomPlaceForButton();
        } else {
          return randomID;
        }
      } else {
        // свободных мест нет
        return false;
      }
    },



    updateGameYellowButtonPressed() {
      this.gameTime = this.gameTime - 2;
      this.gameYellowButtonPressed += 1;
      this.resetYellowButtonInterval();

      this.startYellowButtonInterval();


      this.moveButton('Yellow');


    },

    updateGameGreenButtonPressed() {

      this.gameTime = this.gameTime + 1;
      this.score += 3;
      this.gameGreenButtonPressed += 1;

      this.resetGreenButtonInterval();


      this.startGreenButtonInterval();
      this.moveButton('Green');

    },

    updateGamePurpleButtonPressed() {
      this.gamePurpleButtonPressed += 1;
      this.score += 1;
      this.resetPurpleButtonInterval();
      this.startPurpleButtonInterval();

      this.moveButton('Purple');

    },



    increaseGamesAmount() {
      this.gamesAmount = this.gamesAmount + 1;
    },

    updateGameFieldButtons() {
      const startArray = [];
      startArray.length = 36;
      startArray.fill('')
      this.gameFieldButtons = startArray;
    },
  })
}

export default game();