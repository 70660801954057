import {makeAutoObservable} from "mobx";

function statistic() {
  return makeAutoObservable({
    gameStart: null,
    gameEnd: null,

    allClicks: 0,

    clicksOnButton: 0,
    clicksOnRedButton: 0,

    allClicksLevelOne: 0,
    allClicksLevelTwo: 0,
    allClicksLevelThree: 0,
    allClicksLevelFour: 0,


    resetStatistic() {
      this.allClicks = 0;
      this.clicksOnButton = 0;
      this.clicksOnRedButton = 0;

      this.allClicksLevelOne = 0;
      this.allClicksLevelTwo = 0;
      this.allClicksLevelThree = 0;
      this.allClicksLevelFour = 0;
    },


    /**
     * from App.jsx
     * @param level:number
     */
    clickOnGame(level) {
      this.allClicks = this.allClicks + 1;
      switch (level) {
        case 1:
          this.allClicksLevelOne = this.allClicksLevelOne + 1;
          break;

        case 2:
          this.allClicksLevelTwo = this.allClicksLevelTwo + 1;
          break;

        case 3:
          this.allClicksLevelFour = this.allClicksLevelFour + 1;
          break;

        case 4:
          this.allClicksLevelFour = this.allClicksLevelFour + 1;
          break;

        default:
          break;

      }
    },

    setStartGameTime() {
      this.gameStart = Date.now();
    },

    clickOnButton(color) {
      if (color === 'Red') {
        this.clicksOnRedButton = this.clicksOnRedButton + 1;
      } else {
        this.clicksOnButton = this.clicksOnButton + 1;
      }
    },



  })
}

export default statistic()