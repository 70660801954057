import React, {useEffect, useState} from 'react';
import BaseButton from "./BaseButton";
import {observer} from "mobx-react-lite";
import training from "../store/training";
import assistant from "../store/assistant";
import answers from "../store/answers";
import {motion} from "framer-motion";
import animation from "../store/animation";




const LevelFour = observer(() => {

  const [buttonsArray, setButtonsArray] = useState([]);

  const [randomPlaceForButton, setRandomPlaceForButton] = useState(0);

  const getRandomPlaceForButton = () => {
    const randomID = Math.floor(Math.random() * (49)); // magic number -- будет ошибка, если сделать buttonsArray.length 🤔

    if (randomID === randomPlaceForButton) {
      return getRandomPlaceForButton();
    } else {
      setRandomPlaceForButton(randomID);
    }
  }


  useEffect(() => {
    training.resetSubLevel();

    const array = [];
    array.length = 49; // 7 * 7 field in css
    array.fill(false);
    setButtonsArray(array)

    getRandomPlaceForButton();

    assistant.updateAssistant(answers.headingsLevelFour.currentAnswer[training.currentSubLevel]);

// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getRandomPlaceForButton();
    const interval = setInterval(() => {
      getRandomPlaceForButton();
    }, training.secondsForLevelFour[training.currentSubLevel]);


    return () => {
      clearInterval(interval)
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [training.currentSubLevel])

  return (
    <motion.div className={'level-four'}>

        {buttonsArray.map((item, id) => {
          return (
            <div key={id + 'level-four' + training.currentSubLevel}>
              {
                id ===  randomPlaceForButton?

                    <motion.div
                      variants={animation.buttonVariants[training.currentLevel]}
                      initial={'hidden'}
                      animate={'visible'}


                    >
                      <BaseButton  id={id} color={'Red'} />
                    </motion.div>

                  :
                  (<div></div>)
              }
            </div>
          )

        })}


    </motion.div>
  );
});

export default LevelFour;