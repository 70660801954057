import React, {useEffect} from 'react';
import BaseButton from "./BaseButton";
import training from "../store/training";
import assistant from "../store/assistant";
import {observer} from "mobx-react-lite";
import {motion, AnimatePresence} from "framer-motion";
import animation from "../store/animation";


const container = {
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.3,
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
}




const LevelTwo = observer(() => {

  useEffect(()=> {
    training.resetSubLevel();

    assistant.updateAssistant({
      heading: 'Ну нет! Нельзя же нажимать на красную кнопку! Это правило! Вот на другие кнопки нажимать можно!',
      emotion: 'FacewithRollingEyes'
      }
    );

    training.mixButtonsForLevelTwo();


  }, []);

  return (
    <motion.div
      className={'level-two'}

      variants={container}
      initial="hidden"
      animate="visible"
    >
      <AnimatePresence mode={"popLayout"}>
        {training.mixedAndPressedButtonsForLevelTwo.map((color, id) => {
          return (
            <div key={id + color + 'level-two'}>
              {
                color ?
                  <div>
                    <motion.div
                      variants={animation.buttonVariants[training.currentLevel]}
                      initial={'hidden'}
                      animate={'visible'}
                      exit={'exit'}
                      custom={id}
                    >

                      <BaseButton
                        color={color}
                        id={id}

                      />
                    </motion.div>
                  </div>
                  :
                  (<div className={'nothing'}></div>)

              }
            </div>

        )
        })}


      </AnimatePresence>

    </motion.div>
  );
});

export default LevelTwo;