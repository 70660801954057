import {makeAutoObservable} from "mobx";

function training() {
  return makeAutoObservable({

    // all game
    currentLevel: 1, // 1
    currentSubLevel: 0,

    setCurrentLevel(level) {
      this.currentLevel = level;
    },

    resetSubLevel() {
      this.currentSubLevel = 0;
    },

    increaseSubLevel() {
      this.currentSubLevel = this.currentSubLevel + 1;
    },




    // level 1
    stepsToGameFromLevelOne: [4, 6, 8, 11, 14],

    clicksForStartGame: 15,


    // level 2
    buttonColorsForLevelTwo: [
      'Red',
      'Fuchsia',
      'Purple',
      'White',
      'Gray',
      'Maroon',
      'Yellow',
      'Lime',
      'Aqua',
      'Teal',
      'Blue',
      'Navy',
      'Black',
      'Orange',
      'Silver',
      'Brown',
    ],

    mixedAndPressedButtonsForLevelTwo: [],

    buttonsPressedLevelTwo: 0,

    increaseButtonsPressedLevelTwo() {
      this.buttonsPressedLevelTwo = this.buttonsPressedLevelTwo + 1;
    },
    resetButtonsPressedLevelTwo() {
      this.buttonsPressedLevelTwo = 0;
    },

    mixButtonsForLevelTwo() {
      const shuffleArray = this.buttonColorsForLevelTwo;
      for (let i = shuffleArray.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        [shuffleArray[i], shuffleArray[j]] = [shuffleArray[j], shuffleArray[i]];
      }

      this.mixedAndPressedButtonsForLevelTwo = shuffleArray;
    },

    updatePressedButtonsForLevelTwoMix(color) {
      const newArray = this.mixedAndPressedButtonsForLevelTwo.map((button) => {
        return button === color ? false : button;
      });

      this.mixedAndPressedButtonsForLevelTwo = newArray;
    },


    // level 3

    buttonsForLevelThree: [],

    trueButtonIdForLevelThree: 0,

    pressedButtonsForLevelThree: [],


    // depends on styles with grid
    amountButtonsForLevelThree: [16, 25, 36, 49, 64, 81],

    resetButtonsForLevelThree(sublevel) {
      // есть кнопки, сбрасываем анимацию

      this.buttonsForLevelThree = [];

      setTimeout(() => {
        this.updateButtonsForLevelThree(sublevel)
      }, 2000)
    },

    updateButtonsForLevelThree(sublevel) {
      const array = [];
      array.length = this.amountButtonsForLevelThree[sublevel];

      array.fill('Red');
      this.buttonsForLevelThree = array;

    },


    updatePressedButtonsForLevelThree(id) {
      const newArray = this.buttonsForLevelThree.map((button, idButton) => {
        return id === idButton ? false : button;
      });

      this.buttonsForLevelThree = newArray;

      this.pressedButtonsForLevelThree.push(id)
    },

    resetPressedButtonsForLevelThree() {
      const array = [];
      this.pressedButtonsForLevelThree = array;
    },


    updateTrueButtonIdForLevelThree(id) {
      this.trueButtonIdForLevelThree = id;
    },



    // level 4

    // [3000, 1000, 800, 700, 600, 500] game
    // [3000, 2000, 2000, 2000, 2000, 2000] development
    secondsForLevelFour: [3000, 1000, 800, 700, 600, 500], // ms



    // level 5 (end training, statistic and big button
    bigButtonPressed: false,

    setBigButtonPressed(state) {
      this.bigButtonPressed = state;
      this.setCurrentLevel(6);
    },

    resetGames() {
      this.mixedAndPressedButtonsForLevelTwo = [];
      this.buttonsForLevelThree = [];
      this.pressedButtonsForLevelThree = [];
      this.bigButtonPressed = false;
      this.resetButtonsPressedLevelTwo();
    },



  })
}

export default training()